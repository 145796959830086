
@import url('https://fonts.googleapis.com/css?family=Nunito Sans');
@import url('https://fonts.googleapis.com/css?family=Lexend Deca');

#root {
  font-family: 'Nunito Sans';
}

.login-container{
  display: flex;
  flex-direction: column;
}

.login-error--div{
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.login-error{
  color: red;
}

.login-inputs{
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: row;
  gap: 21px;
  margin-bottom: 32px;
}

.login-email{
  width: 219px;
  border-radius: 8px;
  border:none;
  background-color: #EDF2F7;
  height: 52px;
  padding-left: 12px;
  padding-right: 12px;
}

.login-password{
  width: 219px;
  border-radius: 8px;
  border:none;
  background-color: #EDF2F7;
  height: 52px;
  padding-left: 12px;
  padding-right: 12px;
}

.login-title{
  margin: 76px auto 10px auto;
  font-size: 36px;
  font-weight: 700;
  height: 79px;
  text-shadow: -5px 5px 0px #FFFFFF;
  text-align: center;
}

.login-text{
  margin: 0 auto 50px auto;
  max-width: 853px;
  font-size: 13px;
  line-height: 24px;
  color: #666666;
  text-align: center;
}

.login-button{
  align-self: center;
  justify-content: center;
  align-items: center;
  background-color: #E81E75;
  color: white;
  width: 108px;
  height: 52px;
  border-radius: 8px;
  border: none;
  font-family: 'Arial';
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}

.notFound-container{
  display: flex;
  flex-direction: column;
}

.notFound-img{
  width: 30%;
  margin: 20px auto 80px auto;
}

.no_underline{
  text-decoration: none;
}

.grey {
  color: #666666
}

.dark_grey {
  color: #333333
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  border-bottom: 1px solid #F8E0FB;
}

.logo {
  width: 230px;
  position: absolute;
  left: 50%;
  margin-left: -115px;
}

.login {
  margin-right: 50px;
  cursor: pointer;
  margin-left: auto;
}

.footer {
  display: flex;
  flex-direction: column;
}

.bottom {
  display: flex;
  width: 100%;
  height: 154px;
  align-items: center;
  justify-content: space-between;
}

.bottom--left{
  display: flex;
  width: 500px;
}

.bottom--center{
  display: flex;
  margin: auto;
}

.bottom--right{
  display: flex;
  width: 500px;
  justify-content: end;
}

.logoFooter {
  width: 230px
}

.copyright {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center
}

.line {
  width: 90%;
  border-top: 1px solid #CCD5DF;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.first--link {
  margin-left: 70px;
}

.second--link {
  margin-left: 24px
}

.third--link {
  margin-right: 24px
}

.fourth--link {
  margin-right: 70px
}

.line-in-bottom {
  display: none;
}

.fotter--banner{
  background-color: #F7BFC066;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.fotter--banner--img{
  margin: 22px 0 25px 0
}

.private--btn{
  margin-left: 50px;
  margin-right: auto;
  border: none;
  background-color: #E81E75;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  padding: 5px;
}

@media (max-width: 730px) {

  .login-container{
    height: auto;
  }

  .login-title{
    order: 1;
    height: auto;
  }

  .login-text{
    order: 2;
    height: auto;
    width: auto
  }

  .login-inputs{
    order: 3;
    flex-direction: column;
  }

  .login-email{
    order: 1;
  }

  .login-password{
    order: 2;
  }

  .login-button{
    order: 4;
  }

  .bottom{
    flex-direction: column;
    height: auto;
  }

  .bottom--left{
    flex-direction: column;
    order:2
  }

  .bottom--center{
    flex-direction: column;
    order: 1
  }

  .bottom--right{
    flex-direction: column;
    order: 3
  } 

  .logoFooter{
    justify-self: flex-start;
    order: 1;
    margin: 0 auto 0 auto
  }

  .line-in-bottom {
    margin-top: 33px;
    display: flex;
    order: 2;
    width: 90%;
    border-top: 1px solid #CCD5DF;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }

  .first--link{
    order: 3;
    margin: 32px auto 24px auto
  }
  .second--link{
    order: 4;
    margin: 24px auto 24px auto
  }
  .third--link{
    order: 5;
    margin: 24px auto 24px auto
  }
  .fourth--link{
    order: 6;
    margin: 24px auto 24px auto
  }

  .copyright{
    margin-top: 32px;
  }
  
}

.card {
  justify-self: center;
  align-self: center;
}

/* .card {
  width: 150px;
  overflow: hidden;
} */

.card--img{
  cursor: pointer;
  width: "117px"
}

.card_name{
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  font-family: "Nunito Sans";
}

.card_description{
  color:#E81E75;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  font-family: "Nunito Sans";
}

@media (max-width: 550px) {
  .header {
    flex-direction: column;
    height: auto;
  }

  .logo{
    order: 1;
    margin: 40px auto 0px auto;
    position: unset;
  }

  .private--btn{
    order: 2;
    margin: 20px auto 0px auto;
  }

  .login{
    order: 3;
    margin: 20px auto 15px auto
  }
}
